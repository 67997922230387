import axios from '@/libs/axios';

export default {
  /**
   * Send request for add like to post
   * @returns {Object}
   */
  async addLike(id) {
    const res = await axios.post(`likes/posts/${id}`);
    return res;
  },

  /**
   * Send request for remove like from post
   * @returns {Object}
   */
  async removeLike(id) {
    const res = await axios.delete(`likes/posts/${id}`);
    return res;
  },

  /**
   * Send request for remove like from post
   * @returns {Object}
   */
  async delete(id) {
    const res = await axios.delete(`groups/posts/${id}`);
    return res;
  },

  /**
   * Send request for remove like from post
   * @returns {Object}
   */
  async deleteFromProject(id) {
    const res = await axios.delete(`projects/posts/${id}`);
    return res;
  },
};
