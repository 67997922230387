<template>
  <div>
    <div class="project__item">
      <div class="project__icon dropright">
        <b-dropdown
          :html="'<img src=' + require('@/assets/images/icon-project-gear.svg') + '>'"
          toggle-class="btn-gear"
          menu-class="dropdown-round-box"
          dropright
        >
          <b-dropdown-item
            class="project-setting__item"
            link-class="link_dropdown"
            :to="{
              name: 'strategyProjectsEditor',
              params: { id: project.slug },
              meta: {
                isGroup,
              }
            }"
          >
            <span class="icon-setting-edit"></span>Edit
          </b-dropdown-item>
          <b-dropdown-item
            class="project-setting__item"
            link-class="link_dropdown"
            @click="onDelete"
          >
            <span class="icon-setting-bin"></span>Delete Strategy Project
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="project__head">
        <div class="project__ribbon">
          <span class="crop-text">{{categoryLabel}}</span>
        </div>
        <div class="project__name">
          <ul class="project__action">
            <li style="display: flex; align-items: center;">
              <span v-if="unreadProjectFeedAndMemberCount"
                class="message_badge"
                :class="getClassBadgeLength(unreadProjectFeedAndMemberCount)"
                title="Unread comments"
              >{{unreadProjectFeedAndMemberCount}}</span>
            </li>
            <li :title="project.cnt_post + ' posts'">
              <a href="" @click.prevent="onHandleComments">
                <span class="icon-message-cloud"></span>
              </a>
            </li>
            <li>
              <a href="" class="action-like" @click.prevent="onHandleLike">
                <span
                  v-if="project.like"
                  class="icon-like--active"
                ></span>
                <span v-else class="icon-like--notactive"></span>
                <span class="action-like-num">{{project.cnt_like}}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="project__body">
        <div v-if="true" class="project__body-head">
          <div class="project__avatar">
            <div
              v-if="project.author.avatar"
              alt="avatar"
              class="avatar"
              :style="'background-image: url(' + project.author.avatar + ')'">
            </div>
            <div
              v-else
              alt="avatar"
              class="avatar"
              :style="'background-image: url(' +
                require('@/assets/images/avatar.svg') +
                ')'"
            >
            </div>
          </div>
          <div class="project__author">
            <h4>{{project.author.name}}</h4>
            <span>{{project.created_at}}</span>
          </div>
        </div>
        <div class="user-group__body-text">
          <h3 class="project__title">{{project.name}}</h3>
          {{project.text_short}}
          <ul class="project__category">
            <li v-if="project.style_name" >
              <a style="cursor: default">{{project.style_name}}</a>
            </li>
            <li v-if="isGroup" ><a style="cursor: default">{{project.category_id}}</a></li>
            <li><a style="cursor: default">{{project.type}}</a></li>
          </ul>
        </div>
        <div class="project__more-box">
          <router-link
            :to="{
              name: 'strategyProjectPageView',
              params: { id: project.slug }
            }"
            class="project__more-btn"
          >
            Read more
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue';

import badge from '@/mixins/badge';

export default {
  name: 'CardStrategyProject',
  components: { BDropdown, BDropdownItem },
  mixins: [badge],
  props: {
    project: {
      type: Object,
      required: true,
    },
    isGroup: {
      type: Boolean,
      default: false,
    },
    activeComments: {
      type: Boolean,
      default: false,
    },
    unreadProject: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {
    categoryLabel() {
      return !this.isGroup ? this.project.category_id : 'Group';
    },
    users() {
      if (!(this.project.author || this.project.users)) {
        return [];
      }
      return [this.project.author, ...this.project.users];
    },
    unreadProjectFeedAndMemberCount() {
      return (this.unreadProject
        && Object.keys(this.unreadProject.feed).length + this.unreadProject.members.length) || 0;
    },
  },
  methods: {
    onDelete() {
      this.$eventBus.$emit('deleteProject', this.project.id);
    },
    onHandleLike() {
      if (this.project.like) {
        this.$eventBus.$emit('removeLike', this.project.id);
      } else {
        this.$eventBus.$emit('addLike', this.project.id);
      }
    },
    onHandleComments() {
      if (this.activeComments) {
        this.$emit('hideComments', this.project.id);
      } else {
        this.$emit('showComments', this.project.id);
      }
    },
  },
  mounted() {
    document.querySelectorAll('.link_dropdown').forEach(($link) => {
      $link.classList.remove('dropdown-item');
    });
  },
};
</script>
<style lang="scss" scoped>
::v-deep .btn-gear {
  padding: 0;
  padding-top: 6px;

  &:hover {
    background-color: transparent;
  }
}
.dropright {
  ::v-deep .dropdown-menu.show {
    left: 16px !important;
  }
}
::v-deep .link_dropdown {
  text-align: center;
}

.message_badge {
  &.two {
    width: 30px;
    border-radius: 40%;
  }

  &.three {
    width: 40px;
    border-radius: 40%;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  // position: absolute;
  // top: -10px;
  // right: -10px;

  background-color: red;
  border-radius: 50%;

  z-index: 1;

  color: #fff;
  font-size: 12px;
  font-weight: 600;

  // @media (max-width: 992px) {
  //   right: -20px;
  // }
}
</style>
