<template>
  <div class="messages__box">
    <Avatar classContainer="messages__avatar" :avatar="comment.avatar" />
    <div class="messages-content">
      <div class="messages-content__view">
        <span v-if="unreadPostCount"
          class="message_badge"
          :class="getClassBadgeLength(unreadPostCount)"
        >{{unreadPostCount}}</span>
        <div class="messages-content__head">
          <div class="messages-content__head-left">
            <div class="messages-content__author">{{comment.name}}</div>
            <div class="messages-content__time">{{comment.duration}}</div>
          </div>
          <a
            href="#"
            class="action-like"
            @click.prevent="onHandleLike('post', comment.id)"
          >
            <span v-if="comment.like" class="icon-like--active"></span>
            <span v-if="!comment.like" class="icon-like--notactive"></span>
            <span class="action-like-num">{{comment.cnt_like}}</span>
          </a>
        </div>
        <div class="messages-content__text" v-html="comment.text"></div>
      </div>
      <a
        v-if="me.id === comment.user_id || projectRole === 'owner' || projectRole === 'admin'"
        href="#"
        class="feed__btn_delete_post"
        @click.prevent="$emit('deletePost')"
      >Delete comment</a>
      <div v-if="!page" class="messages-content__actions">
        <a
          v-if="comment.cnt_child > answersShow"
          href=""
          style="position: relative"
          @click.prevent="showMoreReviews"
        >
          View replies ({{comment.cnt_child}})
        </a>
          <span v-if="unreadAnswersToPost.length && comment.cnt_child > answersShow"
            class="message_badge_answer"
            :class="getClassBadgeLength(unreadAnswersToPost.length)"
          >{{unreadAnswersToPost.length}}</span>
          <span
              v-if="comment.cnt_child > answersShow"
          >  |  </span>
        <a
          v-if="activePostAnswer"
          href=""
          @click.prevent="onSetActiveSubmitAnswerComment('post')"
        >
          Add answer...
        </a>
      </div>
      <div class="messages-content__more">
        <div
          v-for="answerComment in answerComments"
          :key="answerComment.id + answerComment.created_at"
          class="messages__box"
        >
          <Avatar classContainer="messages__avatar" :avatar="answerComment.avatar" />
          <div class="messages-content">
            <div class="messages-content__view">
              <span v-if="unreadAnswersToPost
                .filter((answer) => +answer.id === +answerComment.id).length"
                class="message_badge"
                :class="getClassBadgeLength(unreadAnswersToPost
                .filter((answer) => +answer.id === +answerComment.id).length)"
              >{{unreadAnswersToPost.filter((answer) => +answer.id === +answerComment.id).length}}
              </span>
              <div class="messages-content__head">
                <div class="messages-content__head-left">
                  <div class="messages-content__author">{{answerComment.name}}</div>
                  <div class="messages-content__time">{{answerComment.duration}}</div>
                </div>
                <a
                  href="#"
                  class="action-like"
                  @click.prevent="onHandleLike('comment', answerComment.id)"
                >
                  <span v-if="answerComment.like" class="icon-like--active"></span>
                  <span v-if="!answerComment.like" class="icon-like--notactive"></span>
                  <span class="action-like-num">{{answerComment.cnt_like}}</span>
                </a>
              </div>
              <div class="messages-content__text" v-html="answerComment.text"></div>
            </div>
            <a
              v-if="me.id === answerComment.user_id
                || projectRole === 'owner'
                || projectRole === 'admin'
              "
              href="#"
              class="feed__btn_delete_post"
              @click.prevent="onDeleteAnswerComment(answerComment.id)"
            >Delete comment</a>
          </div>
        </div>
        <div v-if="page" class="messages-content__actions">
          <a
            v-if="comment.cnt_child > answersShow"
            href=""
            @click.prevent="showMoreReviews"
          >
            View next replies ({{comment.cnt_child - (page * answersShow)}})
          </a>
            <span
              v-if="comment.cnt_child > answersShow"
            >  |  </span>
          <a
            v-if="activePostAnswer"
            href="" @click.prevent="onSetActiveSubmitAnswerComment('post')"
          >Add answer...</a>
        </div>
        <div v-if="text" style="margin-top: 30px;">
          <Alert :variant="variant" v-if="text">{{ text }}</Alert>
        </div>
        <div
          v-show="activeSubmitAnswerComment"
          :id="'discussion__comment_' + comment.id"
          class="discussion__comment"
        >
          <Avatar classContainer="discussion__avatar" :avatar="me.avatar" />
          <div class="discussion__form">
            <textarea-autosize
              v-model="answerComment.text"
              rows="1"
              :min-height="55"
              class="growTextarea"
              placeholder="Add answer..."
              @keyup.ctrl.enter.native="onSubmitComment"
            ></textarea-autosize>
            <button
              :disabled="!answerComment.text"
              class="discussion__btn"
              @click="onSubmitComment"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
    <Modal
      @close="hideModal(modalDeleteAnswerPost)"
      :id=modalDeleteAnswerPost
      title=""
      buttonTextOk="Delete"
      buttonVariantOk="danger"
      :showButtonCancel="true"
      @cancel="hideModal(modalDeleteAnswerPost)"
      @ok="deleteAnswerComment"
    >
      <p>Are you sure?</p>
    </Modal>
  </div>
</template>
<script>
import Alert from '@/components/Alert.vue';
import Avatar from '@/components/Avatar.vue';
import Modal from '@/components/Modal.vue';

import ProjectService from '@/services/ProjectService';
import PostService from '@/services/PostService';

import exception from '@/mixins/exception';
import alert from '@/mixins/alert';
import me from '@/mixins/me';
import modal from '@/mixins/modal';
import badge from '@/mixins/badge';

export default {
  name: 'CommentContentStrategyProject',
  components: { Alert, Avatar, Modal },
  mixins: [exception, alert, me, modal, badge],
  props: {
    comment: {
      type: Object,
      required: true,
    },
    projectID: {
      type: Number,
      default: 0,
    },
    projectType: {
      type: String,
    },
    projectRole: {
      type: String,
      default: 'guest',
    },
    unreadPostCount: {
      type: Number,
      default: 0,
    },
    unreadAnswersToPost: {
      type: Array,
      required: [],
    },
  },
  data() {
    return {
      activePostAnswer: true,
      activeSubmitAnswerComment: false,
      deleteAnswerCommentId: undefined,
      answersShow: 0,
      page: 0,
      answerComments: [],
      answerComment: {
        text: '',
        parent_id: this.comment.id,
        type: 1,
      },
      modalDeleteAnswerPost: 'modalDeleteAnswerPost',
    };
  },
  created() {
    this.$eventBus.$on('updatedAnswerComment', this.addAnswerComment);
  },
  methods: {
    onSetActiveSubmitAnswerComment(type) {
      if (type === 'post') {
        this.activePostAnswer = false;
      }

      this.activeSubmitAnswerComment = true;
    },
    async showMoreReviews() {
      let res = null;
      this.page += 1;

      try {
        res = await ProjectService.getComments(this.projectID, {
          parent_id: this.comment.id,
          'per-page': 0,
          page: this.page,
        });
        this.answerComments = [...this.answerComments, ...res.data];
        this.answersShow = +res.headers['x-pagination-total-count'];
      } catch (e) {
        this.handleException(e);
      }
    },
    async onSubmitComment() {
      let res = null;
      const data = {
        text: this.answerComment.text.replaceAll('\n', '<br>').replaceAll(' ', '&nbsp;'),
        parent_id: this.answerComment.parent_id,
        type: this.answerComment.type,
      };

      try {
        res = await ProjectService.addComment(this.projectID, data);
        this.answerComment.text = '';
        this.answerComments.push(res.data);
      } catch (e) {
        this.showAndHideAlertSuccess(e.response.data.message, 'danger');
        // this.handleException(e);
      }
    },
    async onDeleteAnswerComment(id) {
      this.deleteAnswerCommentId = id;
      this.showModal(this.modalDeleteAnswerPost);
    },
    async deleteAnswerComment() {
      try {
        await PostService.deleteFromProject(this.deleteAnswerCommentId);
        this.answerComments = this.answerComments
          .filter((comment) => comment.id !== this.deleteAnswerCommentId);
        this.hideModal(this.modalDeleteAnswerPost);
      } catch (e) {
        this.handleException(e);
      }
    },
    onHandleLike(type, id) {
      if (type === 'comment') {
        const $comment = this.answerComments.find((comment) => comment.id === id);

        if ($comment.like) this.removeCommentAnswerLike($comment);
        else this.addCommentAnswerLike($comment);
      } else if (type === 'post') {
        if (this.comment.like) {
          this.$emit('removePostLike', id);
        } else {
          this.$emit('addPostLike', id);
        }
      }
    },
    async removeCommentAnswerLike($comment) {
      try {
        await PostService.removeLike($comment.id);

        const curComment = this.answerComments.find((comment) => comment.id === $comment.id);
        curComment.like = false;
        curComment.cnt_like -= 1;
      } catch (e) {
        this.showAndHideAlertSuccess(e.response.data.message, 'danger');
        this.handleException(e);
      }
    },
    async addCommentAnswerLike($comment) {
      try {
        await PostService.addLike($comment.id);

        const curComment = this.answerComments.find((comment) => comment.id === $comment.id);
        curComment.like = true;
        curComment.cnt_like += 1;
      } catch (e) {
        this.showAndHideAlertSuccess(e.response.data.message, 'danger');
        this.handleException(e);
      }
    },
    addAnswerComment(answerComment) {
      if (this.comment.id !== answerComment.parent_id) return;

      this.comment.cnt_child += 1;

      if (!this.answerComments.length) return;

      this.comment.cnt_child -= 1;

      this.answerComments.push(answerComment);
    },
    beforeDestroy() {
      this.$eventBus.$off('updatedAnswerComment', this.addAnswerComment);
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.discussion__btn:disabled {
  color: #EEE;
}
textarea.growTextarea {
  padding-bottom: 17px;
}

.discussion__btn {
  top: auto;
  bottom: 8px;
}

.feed__btn_delete_post {
  display: block;
  text-align: right;
  padding: 0 30px 5px;
  color: #56a3db;
}

.messages-content__view {
  position: relative;
}

.message_badge {
  &.two {
    width: 30px;
    border-radius: 40%;
  }

  &.three {
    width: 40px;
    border-radius: 40%;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  position: absolute;
  top: -10px;
  right: -10px;

  background-color: red;
  border-radius: 50%;

  z-index: 1;

  color: #fff;
  font-size: 12px;
  font-weight: 600;

  // @media (max-width: 992px) {
  //   right: -20px;
  // }
}

.message_badge_answer {
  &.two {
    width: 30px;
    border-radius: 40%;
  }

  &.three {
    width: 40px;
    border-radius: 40%;
  }

  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  // position: absolute;
  // top: -10px;
  // right: -10px;

  background-color: red;
  border-radius: 50%;

  z-index: 1;

  color: #fff;
  font-size: 12px;
  font-weight: 600;

  // @media (max-width: 992px) {
  //   right: -20px;
  // }
}
</style>
