<template>
  <div :class="classContainer">
    <img v-if="avatar" :src="avatar" alt="">
    <img v-else src="@/assets/images/avatar.svg" alt="">
  </div>
</template>
<script>
export default {
  name: 'Avatar',
  props: {
    classContainer: {
      type: String,
      default: '',
    },
    avatar: {
      type: String,
      default: '',
    },
  },
};
</script>
