<template>
  <div>
    <CardStrategyProject
      :project="project"
      :isGroup="isGroup"
      :activeComments="activeComments"
      :unreadProject="unreadCommentsCount"
      @showComments="onShowComments"
      @hideComments="onHideComments"
    />
    <CommentsStrategyProject
      v-if="activeComments"
      :comments="comments"
      :postsShow="postsShow"
      :totalPosts="totalPosts"
      :projectID="project.id"
      :projectType="project.type"
      :projectRole="project.role"
      @addComment="onAddComment"
      @deletePost="onDeletePost"
      @addPostLike="onAddPostLike"
      @removePostLike="onRemovePostLike"
      @showMorePosts="onShowComments"
    />
    <Modal
      @close="hideModal(modalDeletePost)"
      :id=modalDeletePost
      title=""
      buttonTextOk="Delete"
      buttonVariantOk="danger"
      :showButtonCancel="true"
      @cancel="hideModal(modalDeletePost)"
      @ok="deletePost"
    >
      <p>Are you sure?</p>
    </Modal>
  </div>
</template>
<script>
import CardStrategyProject from '@/views/profile/Components/CardStrategyProject.vue';
import CommentsStrategyProject from '@/views/profile/Components/CommentsStrategyProject.vue';
import Modal from '@/components/Modal.vue';

import exception from '@/mixins/exception';
import scroll from '@/mixins/scroll';
import modal from '@/mixins/modal';
import me from '@/mixins/me';
import websocket from '@/mixins/websocket';

import ProjectService from '@/services/ProjectService';
import PostService from '@/services/PostService';

export default {
  name: 'ContainerCardStrategyProject',
  components: { CardStrategyProject, CommentsStrategyProject, Modal },
  mixins: [exception, scroll, modal, me, websocket],
  props: {
    project: {
      type: Object,
      required: true,
    },
    isGroup: {
      type: Boolean,
      default: false,
    },
    unreadCommentsCount: {
      type: Object,
    },
  },
  data() {
    return {
      activeComments: false,
      comments: [],
      deletePostId: undefined,
      postsShow: 0,
      page: 0,
      totalPosts: 0,
      modalDeletePost: 'modalDeletePost',
    };
  },
  created() {},
  methods: {
    onHideComments() {
      this.activeComments = false;
    },
    async onShowComments() {
      let res = null;
      this.postsShow += 5;
      this.page += 1;

      if (!this.comments.length) {
        this.subscribeToChannel('project', this.setUnreadPostsAndCommentsFromSocket, this.project.id);
      }

      try {
        res = await ProjectService.getComments(this.project.id, {
          'per-page': 5,
          page: this.page,
        });

        this.comments = [...this.comments, ...res.data];
        this.totalPosts = +res.headers['x-pagination-total-count'];
        this.activeComments = true;
      } catch (e) {
        this.handleException(e);
      }
    },
    onAddComment(comment) {
      this.comments.unshift(comment);
    },
    async onDeletePost(id) {
      this.deletePostId = id;
      this.showModal(this.modalDeletePost);
    },
    async deletePost() {
      try {
        await PostService.deleteFromProject(this.deletePostId);
        this.comments = this.comments.filter((comment) => comment.id !== this.deletePostId);
        this.hideModal(this.modalDeletePost);
      } catch (e) {
        this.handleException(e);
      }
    },
    async onAddPostLike(id) {
      try {
        await PostService.addLike(id);

        const curComment = this.comments.find((comment) => comment.id === id);
        curComment.like = true;
        curComment.cnt_like += 1;
      } catch (e) {
        console.log(e.response, 'danger');
        this.handleException(e);
      }
    },
    async onRemovePostLike(id) {
      try {
        await PostService.removeLike(id);

        const curComment = this.comments.find((comment) => comment.id === id);
        curComment.like = false;
        curComment.cnt_like -= 1;
      } catch (e) {
        console.log(e.response, 'danger');
        this.handleException(e);
      }
    },
    setUnreadPostsAndCommentsFromSocket(event) {
      const unreadPostsAndComments = JSON.parse(event.data).message;
      const newFeed = JSON.parse(event.data).message_update;

      if (!this
        .$store
        .getters
        .getUnreadPosts.length) {
        this.$store.commit(
          'SET_UNREAD_POSTS',
          [...unreadPostsAndComments.feed],
        );

        this.$store.commit(
          'SET_UNREAD_POSTS_COUNT',
          this.$store.getters.getUnreadPosts.length,
        );
      }

      if (newFeed) {
        if (!newFeed.parent_id) this.$eventBus.$emit('updatedPost', newFeed);
        else this.$eventBus.$emit('updatedAnswerComment', newFeed);
      }
    },
  },
  beforeDestroy() {
    this.$store.commit('SET_UNREAD_POSTS_COUNT', 0);
    this.$store.commit('SET_UNREAD_POSTS', []);
  },
};
</script>
