var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"project__item"},[_c('div',{staticClass:"project__icon dropright"},[_c('b-dropdown',{attrs:{"html":'<img src=' + require('@/assets/images/icon-project-gear.svg') + '>',"toggle-class":"btn-gear","menu-class":"dropdown-round-box","dropright":""}},[_c('b-dropdown-item',{staticClass:"project-setting__item",attrs:{"link-class":"link_dropdown","to":{
            name: 'strategyProjectsEditor',
            params: { id: _vm.project.slug },
            meta: {
              isGroup: _vm.isGroup,
            }
          }}},[_c('span',{staticClass:"icon-setting-edit"}),_vm._v("Edit ")]),_c('b-dropdown-item',{staticClass:"project-setting__item",attrs:{"link-class":"link_dropdown"},on:{"click":_vm.onDelete}},[_c('span',{staticClass:"icon-setting-bin"}),_vm._v("Delete Strategy Project ")])],1)],1),_c('div',{staticClass:"project__head"},[_c('div',{staticClass:"project__ribbon"},[_c('span',{staticClass:"crop-text"},[_vm._v(_vm._s(_vm.categoryLabel))])]),_c('div',{staticClass:"project__name"},[_c('ul',{staticClass:"project__action"},[_c('li',{staticStyle:{"display":"flex","align-items":"center"}},[(_vm.unreadProjectFeedAndMemberCount)?_c('span',{staticClass:"message_badge",class:_vm.getClassBadgeLength(_vm.unreadProjectFeedAndMemberCount),attrs:{"title":"Unread comments"}},[_vm._v(_vm._s(_vm.unreadProjectFeedAndMemberCount))]):_vm._e()]),_c('li',{attrs:{"title":_vm.project.cnt_post + ' posts'}},[_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.onHandleComments($event)}}},[_c('span',{staticClass:"icon-message-cloud"})])]),_c('li',[_c('a',{staticClass:"action-like",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.onHandleLike($event)}}},[(_vm.project.like)?_c('span',{staticClass:"icon-like--active"}):_c('span',{staticClass:"icon-like--notactive"}),_c('span',{staticClass:"action-like-num"},[_vm._v(_vm._s(_vm.project.cnt_like))])])])])])]),_c('div',{staticClass:"project__body"},[(true)?_c('div',{staticClass:"project__body-head"},[_c('div',{staticClass:"project__avatar"},[(_vm.project.author.avatar)?_c('div',{staticClass:"avatar",style:('background-image: url(' + _vm.project.author.avatar + ')'),attrs:{"alt":"avatar"}}):_c('div',{staticClass:"avatar",style:('background-image: url(' +
              require('@/assets/images/avatar.svg') +
              ')'),attrs:{"alt":"avatar"}})]),_c('div',{staticClass:"project__author"},[_c('h4',[_vm._v(_vm._s(_vm.project.author.name))]),_c('span',[_vm._v(_vm._s(_vm.project.created_at))])])]):_vm._e(),_c('div',{staticClass:"user-group__body-text"},[_c('h3',{staticClass:"project__title"},[_vm._v(_vm._s(_vm.project.name))]),_vm._v(" "+_vm._s(_vm.project.text_short)+" "),_c('ul',{staticClass:"project__category"},[(_vm.project.style_name)?_c('li',[_c('a',{staticStyle:{"cursor":"default"}},[_vm._v(_vm._s(_vm.project.style_name))])]):_vm._e(),(_vm.isGroup)?_c('li',[_c('a',{staticStyle:{"cursor":"default"}},[_vm._v(_vm._s(_vm.project.category_id))])]):_vm._e(),_c('li',[_c('a',{staticStyle:{"cursor":"default"}},[_vm._v(_vm._s(_vm.project.type))])])])]),_c('div',{staticClass:"project__more-box"},[_c('router-link',{staticClass:"project__more-btn",attrs:{"to":{
            name: 'strategyProjectPageView',
            params: { id: _vm.project.slug }
          }}},[_vm._v(" Read more ")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }