<template>
  <div>
    <div
      v-if="$route.name === 'strategyProjectPageView'"
      class="discussion"
    >
      <div class="discussion__head">
        <div class="container">
          <div class="discussion__head-wrapper">
            <span style="position: relative;">
              <span v-if="unreadFeedsCount"
                class="message_badge"
                :class="getClassBadgeLength(unreadFeedsCount)"
              >{{unreadFeedsCount}}</span>
              Discussion
            </span>
          </div>
        </div>
      </div>
      <div v-if="true" class="discussion__body">
        <div class="container">
          <div class="discussion__box">
            <div class="discussion__title">Leave a Reply</div>
            <div class="discussion__comment">
              <Avatar classContainer="discussion__avatar" :avatar="me.avatar" />
              <div class="discussion__form">
                <textarea-autosize
                  v-model="comment.text"
                  class="growTextarea"
                  rows="1"
                  placeholder="Comment..."
                  @keyup.ctrl.enter.native="onSubmitComment"
                ></textarea-autosize>
                <button
                  class="discussion__btn"
                  :disabled="!comment.text"
                  @click="onSubmitComment"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      :id="'comments_container_' + projectID"
      :class="{ container_comment_view: $route.name === 'strategyProjectPageView' }"
    >
      <div v-if="$route.name !== 'strategyProjectPageView'" class="project__messages">
        <div
          v-if="projectRole !== 'guest' && projectRole !== 'request'"
          class="discussion__comment"
        >
          <div class="discussion__avatar">
            <img v-if="!me.avatar" src="@/assets/images/avatar.svg" :title="me.name">
            <img v-else :src="me.avatar" :title="me.name">
          </div>
          <div class="discussion__form">
            <textarea-autosize
              v-model="comment.text"
              class="growTextarea"
              rows="1"
              placeholder="Comment..."
              @keyup.ctrl.enter.native="onSubmitComment"
            ></textarea-autosize>
            <button
              class="discussion__btn"
              :disabled="!comment.text"
              @click="onSubmitComment"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <Alert :variant="variant" v-if="text">{{ text }}</Alert>
      <CommentContentStrategyProject
        v-for="comment in comments"
        :key="comment.id + comment.created_at"
        :comment="comment"
        :projectID="projectID"
        :projectType="projectType"
        :projectRole="projectRole"
        :unreadPostCount="unreadPosts.filter((post) => +post.id === +comment.id).length"
        :unreadAnswersToPost="unreadPosts.filter((post) => +post.parent_id === +comment.id)"
        @addPostLike="onAddPostLike"
        @removePostLike="onRemovePostLike"
        @deletePost="$emit('deletePost', comment.id)"
      />
      <a
        v-if="totalPosts > postsShow"
        class="btn_view_more_posts"
        :class="customClass"
        href=""
        @click.prevent="$emit('showMorePosts')"
      >View more posts...</a>
    </div>
  </div>
</template>
<script>
import CommentContentStrategyProject from '@/views/profile/Components/CommentContentStrategyProject.vue';

import Alert from '@/components/Alert.vue';
import Avatar from '@/components/Avatar.vue';

import ProjectService from '@/services/ProjectService';

import scroll from '@/mixins/scroll';
import me from '@/mixins/me';
import exception from '@/mixins/exception';
import alert from '@/mixins/alert';
import badge from '@/mixins/badge';

export default {
  name: 'CommentsStrategyProject',
  components: {
    Alert,
    CommentContentStrategyProject,
    Avatar,
  },
  mixins: [me, scroll, exception, alert, badge],
  props: {
    comments: {
      type: Array,
      required: true,
    },
    projectID: {
      type: Number,
      default: 0,
    },
    projectType: {
      type: String,
    },
    projectRole: {
      type: String,
      default: 'guest',
    },
    postsShow: {
      type: Number,
      default: 0,
    },
    totalPosts: {
      type: Number,
      default: 0,
    },
    customClass: {
      type: String,
    },
  },
  data() {
    return {
      comment: {
        text: '',
        type: 1,
      },
    };
  },
  created() {
    this.$eventBus.$on('updatedPost', this.addComments);
    this.$eventBus.$on('updatedAnswerComment', this.updateUnreadPosts);
  },
  computed: {
    unreadFeedsCount() {
      return this.$store.getters.getUnreadPostsCount;
    },
    unreadPosts() {
      return this.$store.getters.getUnreadPosts;
    },
  },
  methods: {
    async onSubmitComment() {
      let res = null;
      const data = {
        text: this.comment.text.replaceAll('\n', '<br>').replaceAll(' ', '&nbsp;'),
        type: this.comment.type,
      };

      try {
        res = await ProjectService.addComment(this.projectID, data);
        this.showAndHideAlertSuccess('The post added sussessfully');
        this.comment.text = '';
        this.$emit('addComment', res.data);
      } catch (e) {
        this.showAndHideAlertSuccess(e.response.data.message, 'danger');
        this.handleException(e);
      }
    },
    async onAddPostLike(id) {
      this.$emit('addPostLike', id);
    },
    async onRemovePostLike(id) {
      this.$emit('removePostLike', id);
    },
    updateUnreadPosts(posts) {
      if (this.projectID !== posts.source.id) return;
      if (this.$store.getters.getUnreadPosts.find((post) => +post.id === +posts.id)) return;
      this.$store.commit(
        'SET_UNREAD_POSTS_COUNT',
        this.$store.getters.getUnreadPostsCount + 1,
      );
      this.$store.commit(
        'SET_UNREAD_POSTS',
        [...this.$store.getters.getUnreadPosts, ...[posts]],
      );
    },
    addComments(comment) {
      if (this.projectID !== comment.source.id) return;

      this.comments.unshift(comment);

      if (this.$store.getters.getUnreadPosts.find((post) => +post.id === +comment.id)) return;
      this.$store.commit(
        'SET_UNREAD_POSTS_COUNT',
        this.$store.getters.getUnreadPostsCount + 1,
      );
      this.$store.commit(
        'SET_UNREAD_POSTS',
        [...this.$store.getters.getUnreadPosts, ...[comment]],
      );
    },
  },
  mounted() {
    if (this.$route.name !== 'strategyProjectPageView') {
      this.scrollToTop(`comments_container_${this.projectID}`);
    }

    document.body.classList.remove('page-layout');
  },
  beforeDestroy() {
    this.$eventBus.$off('updatedPost', this.addComments);
  },
};
</script>
<style lang="scss" scoped>
.discussion__btn:disabled {
  color: #EEE;
}

.container_comment_view {
  max-width: 756px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 30px;
}

textarea.growTextarea {
  padding-bottom: 17px;
}

.discussion__btn {
  top: auto;
  bottom: 8px;
}

.btn_view_more_posts {
  margin: 0 0 50px;
  display: inline-block;
}

.btn_view_more_posts.marginTop {
    margin-top: 50px;
}

.message_badge {
  &.two {
    width: 30px;
    border-radius: 40%;
  }

  &.three {
    width: 40px;
    border-radius: 40%;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  position: absolute;
  top: -10px;
  right: -10px;

  background-color: red;
  border-radius: 50%;

  z-index: 1;

  color: #fff;
  font-size: 12px;
  font-weight: 600;

  // @media (max-width: 992px) {
  //   right: -20px;
  // }
}
</style>
